<template>
    <div class="index">
        <div class="container">
            <div class="title">
                <span>预约须知文字设置</span>
                <el-button type="primary" size="small" v-if="!editStatus" @click="changeStatus">编辑</el-button>
                <el-button type="primary" size="small" v-else @click="submit">确认</el-button>
            </div>
            <!-- maxlength="900" -->
            <el-input type="textarea" autosize placeholder="请输入内容(限制1000字)" v-model="textarea" maxlength="1000" :readonly="!editStatus" >
            </el-input>
        </div>
    </div>
</template>

<script>
import { getNotice, updateNotice } from '@/api/consultation/notice'
export default {
    data() {
        return {
            textarea: `一、来自“高风险区”(离开风险区域为1-7天)的患者，健康码为红码的患者，有新冠肺炎常见症状者或其他流行病学史的患者,90天内有我国港台地区或境外国家的出行史或居住史的患者，我院会根据相关要求，分流至发热门诊就诊。有上述旅居史的家属，非必要请不来院。
            二、健康码为黄码的患者至急诊黄码帐篷筛查，健康码转为绿码后，按绿码患者相关要求执行
            三、健康码为绿码，无新冠肺炎常见症状或其他流行病学史的患者:
            1.省外低风险地区患者，需持连续3天3次核酸阴性结果，且其中至少有一次为四川省内24小时核酸阴性结果方可就诊;
            ⒉省内低风险地区、省内外非风险地区患者，需持四川省内24小时内核酸阴性结果就诊。
            3.若接受需要取下口罩的操作(纤支镜、胃肠镜、喉镜、鼻内镜、经食道超声心动图、肺功能、部分皮肤科治疗及面瘫治疗等无法佩戴口罩的各类检查与治疗)，根据来自地区需符合以下条件:
            (1）来自“非风险区”、成都市“低风险区”的患者:需持四川省内24小时核酸阴性结果。
            (2)来自成都市外“低风险区”的患者:需连续3天3次核酸检测阴性，且其中至少有一次为四川省内24小时核酸阴性结果。四、对于提级管理地区的患者，根据“四川疾控”官微最新“四川疾控健康提示”及“成都疾控”官微“来(返)蓉人员疫情防控最新政策”执行。
            五、危急重症患者请到急诊就诊。
            六、门诊血透患者和长期放疗患者每次到我院均需持有24小时内核酸阴性报告。七、挂号、看诊相关规定:
            1.我院门诊施行“全预约诊疗服务”模式，请患者朋友们提前预约挂号;
            2.实名制就诊:挂号信息与患者身份信息不符合，或患者未到现场者，医生有权拒绝看诊;
            3.就诊期间，请客观真实配合流行病学调查，以及“三级预检分诊”、“一室—患”等疫情常态化防控要求，否则可能承担相应法律责任。
            4.预约相关规则:
            (1)门诊号源预约周期为7天，放号时间为:1)早上8:00开放第7天的号源;
            2)晚上8:00开放7天内的爽约号源;(2)7天内预约未支付操作限五次以内，超过五次系统将自动取消预约资格。
            (3)支付成功后无需取号，请持身份证或电子就诊卡二维码报到候诊。
            (4)请严格按照按所挂号源的就诊院区、就诊时段到相应的就诊区域报到，上午号11:30前，下午号15:30前必须到达就诊区报到候诊。
            (5)心理咨询门诊请严格按照提示时段就诊，过时作废。
            (6）退号必须在就诊前48小时操作，但因疫情防控要求暂不能就诊的患者，待满足就诊条件后，凭原有挂号记录到相应咨询台，可安排同专业出诊医生看诊;30天内预约、取消次数不超过10次;通过“当日挂号”挂取的当日号源不可退号。`,
            editStatus: false
        }
    },
    methods: {
        // 点击编辑
        changeStatus() {
            this.editStatus = true;
        },
        // 点击确认
        submit() {
            this.editStatus = false;
            this.updateInfo();
        },
        // 获取用户之前设置的咨询信息
        async getInfo() {
            const res = await getNotice();
            console.log(res);
            if (res.code === 200) {
                this.textarea = res.data;
            }
        },
        async updateInfo() {
            let info = new FormData();
            info.append('memo', this.textarea)
            const res = await updateNotice(info);
            console.log(res);
            if (res.code === 200) {
                this.$message({
                    showClose: true,
                    message: '修改成功！',
                    type: 'success'
                });
                
            } else {
                this.$message({
                    showClose: true,
                    message: '修改失败！' + res.msg,
                    type: 'error'
                });
            }
            this.getInfo();

        }
    },
    created() {
        this.getInfo();
    }
}
</script>

<style lang="scss" scoped>
.index {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 20px;

    .container {
        padding: 20px;
        margin: 0 auto;
        width: 850px;
        box-sizing: border-box;
        height: 100%;
        overflow-y: scroll;
        box-shadow: 0px 2px 4px 0px rgb(229 0 74 / 30%);

        .title {
            margin-bottom: 20px;

            span {
                display: inline-block;
                margin-right: 10px;
            }
        }
    }
}
</style>