import request from '@/utils/request'
// 预约须知
// 获取用户之前设置的
export function getNotice(params) {
    return request({
      url: `/reserve/memo`,
      method: 'get',
      params
    });
}
// 修改
export function updateNotice(data) {
    return request({
      url: `/reserve/memo`,
      method: 'put',
      data
    });
}